import { Hotel } from "typings/microserviceModels/hotels-flex-module";
import { useHotelsContext } from "components/flexComponents/Hotels/components/HotelsContext";
import { useEgClickstream } from "@shared-ui/clickstream-analytics-context";
import { affinityProductCardSelectedClickstreamEvent } from "components/flexComponents/Hotels/utils/affinityClickstreamEvents";
import { AFFINITY_DESTINATION_LANDING_PAGE_TYPES } from "./constants";

export const fireAffinityProductCardSelectedClickstreamEvent = (hotel: Hotel, index: number) => {
  const { context } = useHotelsContext();
  const { searchContext } = context;
  const { affinity, pageType, location } = searchContext;
  const isAffinityDestinationLandingPage = AFFINITY_DESTINATION_LANDING_PAGE_TYPES.includes(pageType);
  const track = useEgClickstream();

  return () => {
    if (affinity && isAffinityDestinationLandingPage) {
      const affinityProductCardSelectedEvent = affinityProductCardSelectedClickstreamEvent(
        location?.id.toString(),
        location?.name,
        affinity.code,
        hotel?.hotelId,
        index
      );
      track(affinityProductCardSelectedEvent);
    }
  };
};
