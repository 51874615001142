// Types
import { Hotel } from "typings/microserviceModels/hotels-flex-module";
import { ExtendedContextStore } from "src/typings/flexFramework/FlexDefinitions";
import { cropResizeSrc, alternateCropResizeSrc } from "src/components/shared/BlossomImage/BlossomImage";
import { ImageProperties } from "../components/SummarizedHotelCard/helpers/image-properties";

/**
 * Function to get the optimized image given an Hotel
 * @param {Hotel} hotel
 */
export function getOptimizedImageUrl(hotel: Hotel, context: ExtendedContextStore, imageProperties?: ImageProperties) {
  const { hotelImageUrls } = hotel;
  const imageDimension = context?.deviceInformation?.mobile ? { width: 340, height: 150 } : { width: 420, height: 224 };
  // const width = isMobile ? 340 : 420;
  // const height = isMobile ? 150 : 224;
  const hotelImageUrl =
    hotelImageUrls &&
    hotelImageUrls.y &&
    (imageProperties
      ? alternateCropResizeSrc(hotelImageUrls.y, imageProperties)
      : cropResizeSrc(hotelImageUrls.y, imageDimension, "medium"));

  return hotelImageUrl || hotelImageUrls?.y;
}
