import * as React from "react";

import { UitkCard, UitkCardLink } from "uitk-react-cards";
import { UitkLayoutFlex, UitkLayoutFlexItem } from "uitk-react-layout-flex";
import { UitkFigure, UitkImage } from "uitk-react-images";
import { UitkHeading, UitkText } from "uitk-react-text";
import { UitkRating } from "uitk-react-rating";
import { useLocalization } from "@shared-ui/localization-context";
import { UitkSpacing } from "uitk-react-spacing";

import PriceLockup from "components/flexComponents/Hotels/views/HotelsViews/components/shared/PriceLockup";
import { PriceLockupAlign } from "components/shared/PriceLockup/typings";
import DistanceOrRating from "components/flexComponents/Hotels/views/HotelsViews/components/shared/DistanceOrRating";
import DescriptionOrReview from "components/flexComponents/Hotels/views/HotelsViews/components/shared/DescriptionOrReview";
import AmenitiesOrRecommendation from "components/flexComponents/Hotels/views/HotelsViews/components/shared/AmenitiesOrRecommendation";
import ValueProposition from "components/flexComponents/Hotels/views/HotelsViews/components/shared/ValueProposition";
import { getHotelLink, getHotelLinkRel } from "components/flexComponents/Hotels/components/HotelCard";

import { ratingIcon } from "components/utility/RatingUtil";
import { TrackedLink } from "components/utility/analytics/TrackedLink";

import { HotelPriceLockup } from "src/components/flexComponents/Hotels/utils/A11y/HotelPriceLockup";
import { getOptimizedImageUrl } from "src/components/flexComponents/Hotels/utils/hotelOptimizedImage";
import { fireAffinityProductCardSelectedClickstreamEvent } from "src/components/flexComponents/Hotels/utils/fireAffinityProductCardSelectedClickstreamEvent";
import { HotelImageCardProps } from "src/components/flexComponents/Hotels/typings";

const HotelCard = (props: HotelImageCardProps) => {
  const { context, model, hotel, index } = props;
  const { callToAction, destinationId } = model;
  const { hotelName, hotelStarRating, hotelFormattedFullAddress } = hotel;

  const { formatText } = useLocalization();

  const hotelImageUrl = getOptimizedImageUrl(hotel, context);

  return (
    <UitkCard onClick={fireAffinityProductCardSelectedClickstreamEvent(hotel, index)}>
      <UitkLayoutFlex>
        <UitkLayoutFlexItem minWidth="half_width" shrink={0}>
          <div>
            <UitkFigure className="all-image-fit-cover">
              <UitkImage src={hotelImageUrl} alt={hotelName} placeholderImage lazyLoading="lazy" />
            </UitkFigure>
          </div>
        </UitkLayoutFlexItem>

        <UitkLayoutFlexItem grow={1}>
          <div>
            <UitkSpacing padding="three">
              <UitkLayoutFlex direction="column">
                <UitkLayoutFlex justifyContent="space-between">
                  <UitkLayoutFlex direction="column">
                    <UitkHeading tag="h3" size={5} overflow="truncate-2-lines">
                      {hotelName}
                    </UitkHeading>
                    <UitkRating
                      rating={hotelStarRating}
                      icon={ratingIcon(context.locale, context.site.id)}
                      className={context.site.brand}
                    />
                    <UitkText size={300}>{hotelFormattedFullAddress}</UitkText>
                  </UitkLayoutFlex>
                  <DistanceOrRating model={model} hotel={hotel} alignment="right" context={context} />
                </UitkLayoutFlex>
                <UitkSpacing margin={{ blockstart: "three" }}>
                  <PriceLockup
                    hotel={hotel}
                    hotelLink={getHotelLink(hotel, model.destinationId, context)}
                    align={PriceLockupAlign.LEFT}
                    priceA11y={new HotelPriceLockup(hotel, formatText)}
                    hasGetRatesText
                  />
                </UitkSpacing>
                <UitkSpacing margin={{ blockstart: "three" }}>
                  <DescriptionOrReview model={model} hotel={hotel} />
                </UitkSpacing>
                <UitkSpacing margin={{ blockstart: "three" }}>
                  <AmenitiesOrRecommendation model={model} hotel={hotel} />
                </UitkSpacing>
                <UitkSpacing margin={{ blockstart: "three" }}>
                  <ValueProposition hotel={hotel} />
                </UitkSpacing>
              </UitkLayoutFlex>
            </UitkSpacing>
          </div>
        </UitkLayoutFlexItem>
      </UitkLayoutFlex>

      <UitkCardLink>
        <TrackedLink
          data-testid={`hotelcard-link-${hotelName}`}
          aria-label={hotelName}
          moduleName="hotels"
          rfrr="card"
          rel={getHotelLinkRel(callToAction)}
          href={getHotelLink(hotel, destinationId || context.searchContext.location?.id, context, callToAction)}
        />
      </UitkCardLink>
    </UitkCard>
  );
};

export default HotelCard;
