import * as React from "react";
import { UitkHeading, UitkText } from "uitk-react-text";
import { UitkSpacing } from "uitk-react-spacing";

interface ModuleTitleProps {
  heading: string;
  subHeading?: string;
}

export const ModuleTitle: React.FC<ModuleTitleProps> = ({ heading, subHeading }) => {
  return (
    <>
      <UitkSpacing margin={{ blockend: "one" }}>
        <UitkHeading tag="h2" size={4}>
          {heading}
        </UitkHeading>
      </UitkSpacing>
      {subHeading && <UitkText size={300}>{subHeading}</UitkText>}
    </>
  );
};

ModuleTitle.displayName = "ModuleTitle";
