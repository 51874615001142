import { useLocalization } from "@shared-ui/localization-context";

import ImageTop from "components/flexComponents/Hotels/views/HotelsViews/components/ImageTop/ImageTop";
import ImageLeftSmall from "components/flexComponents/Hotels/views/HotelsViews/components/ImageLeftSmall/ImageLeftSmall";
import ImageLeftLarge from "components/flexComponents/Hotels/views/HotelsViews/components/ImageLeftLarge/ImageLeftLarge";
import ImageHalf from "components/flexComponents/Hotels/views/HotelsViews/components/ImageHalf/ImageHalf";

import { Hotel } from "typings/microserviceModels/hotels-flex-module";
import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";
import { getFormattedDistance, roundDistance } from "../../utils/getFormattedDistance";
import { HotelsViewTypes, TopLevelViewClassName } from "../../typings";

export const hotelsViewTypes: HotelsViewTypes = {
  "image-top": ImageTop,
  "image-left-small": ImageLeftSmall,
  "image-left-large": ImageLeftLarge,
  "image-half": ImageHalf,
};

export const topLevelViewClassName: TopLevelViewClassName = {
  "image-top": "HotelsImageTop",
  "image-left-small": "HotelsImageLeftSmall",
  "image-left-large": "HotelsImageLeftLarge",
  "image-half": "HotelsImageHalf",
};

export const getDirectDistance = (hotel: Hotel, context: ExtendedContextStore) => {
  const { directDistance } = hotel;
  if (!directDistance) {
    return;
  }

  const { value, unit } = directDistance;
  const isValidUnit = unit === DISTANCE_UNIT.KILOMETERS || unit === DISTANCE_UNIT.MILES;
  if (!(value && isValidUnit)) {
    return;
  }

  const { location } = context.searchContext;
  const { type: locationType, name: locationName } = { ...location };

  const locationTypesUsedAsOrigin = [
    LOCATION_TYPE.POI,
    LOCATION_TYPE.NEIGHBORHOOD,
    LOCATION_TYPE.AIRPORT,
    LOCATION_TYPE.TRAIN_STATION,
    LOCATION_TYPE.METRO_STATION,
  ] as string[];
  const originType = locationTypesUsedAsOrigin.includes(locationType) ? "location" : "citycenter";

  const { formatText } = useLocalization();

  const distanceValue = roundDistance(value, 2);
  const formattedDistanceValue = getFormattedDistance(distanceValue, context?.locale);

  const distanceTextResponses = {
    km: {
      location: formatText("hotels.distanceToPoi.kilometers", distanceValue, formattedDistanceValue, locationName),
      citycenter: formatText(
        "nearestLocationContent.cityCenterDistance.text.km",
        distanceValue,
        formattedDistanceValue
      ),
    },
    mi: {
      location: formatText("hotels.distanceToPoi.miles", distanceValue, formattedDistanceValue, locationName),
      citycenter: formatText(
        "nearestLocationContent.cityCenterDistance.text.mi",
        distanceValue,
        formattedDistanceValue
      ),
    },
  };

  return distanceTextResponses[unit][originType];
};

export const expandoCollapsedTitle = (contentRecipe: string) => {
  const { formatText } = useLocalization();

  return contentRecipe === CONTENT_TYPE.REVIEW
    ? formatText("hotelsViews.expando.showReview")
    : formatText("hotelsViews.expando.showDetails");
};

export const expandoExpandedTitle = (contentRecipe: string) => {
  const { formatText } = useLocalization();

  return contentRecipe === CONTENT_TYPE.REVIEW
    ? formatText("hotelsViews.expando.hideReview")
    : formatText("hotelsViews.expando.hideDetails");
};

enum LOCATION_TYPE {
  AIRPORT = "airport",
  METRO_STATION = "metro_station",
  TRAIN_STATION = "train_station",
  NEIGHBORHOOD = "neighborhood",
  POI = "point_of_interest",
}

enum DISTANCE_UNIT {
  KILOMETERS = "km",
  MILES = "mi",
}

export enum CONTENT_TYPE {
  REVIEW = "review",
  DESCRIPTION = "description",
}

export enum CARD_LAYOUT_TYPE {
  GRID = "grid",
  LIST = "list",
}
