import * as React from "react";

import { LocalizedText } from "@shared-ui/localization-context";
import { UitkIcon, UitkIconSize } from "uitk-react-icons";
import { UitkList } from "uitk-react-list";
import { UitkText } from "uitk-react-text";

import { Hotel, NearPoi } from "typings/microserviceModels/hotels-flex-module";

/*
 * Types
 */
interface NearPoiDirections {
  amount: number;
  unit: TimeUnit;
}

interface NearPoiModel {
  icon: string;
  locToken: string;
  locData: [number, string];
}

enum TimeUnit {
  WalkingMinutes,
  DrivingMinutes,
  DrivingHours,
}

/*
 * Conversions and generic utils
 */
/**
 * Walk time is in minutes at 5km/h, drive time is in minutes at 40km/h
 */
const walkTimeToDriveTime = (walkTime: number) => Math.round(walkTime / 8);

const minutesToHours = (minutes: number) => Math.round(minutes / 60);

/*
 * Business logic
 */

/**
 * Intermediary representation of the directions based on its value in minutes.
 */
const getDirections = (walkTimeMin: number): NearPoiDirections => {
  if (walkTimeMin < 30) {
    return { amount: walkTimeMin, unit: TimeUnit.WalkingMinutes };
  }

  const driveTimeMin = walkTimeToDriveTime(walkTimeMin);
  if (driveTimeMin < 60) {
    return { amount: driveTimeMin, unit: TimeUnit.DrivingMinutes };
  }

  return { amount: minutesToHours(driveTimeMin), unit: TimeUnit.DrivingHours };
};

/**
 * String representation of a time unit for use in localization token.
 */
const getLocUnit = (unit: TimeUnit) => {
  if (unit === TimeUnit.WalkingMinutes) {
    return "walkTimeMinutes";
  }

  if (unit === TimeUnit.DrivingMinutes) {
    return "driveTimeMinutes";
  }

  return "driveTimeHours";
};

/**
 * Localization token to output based on time distance to POI.
 */
const getLocToken = ({ unit }: NearPoiDirections) => `hotels.nearPoi.${getLocUnit(unit)}`;

/**
 * Localization data to output based on time distance to POI.
 */
const getLocData = ({ amount }: NearPoiDirections, poiName: string): [number, string] => [amount, poiName];

/**
 * Icon to output based on time unit.
 */
const getIcon = ({ unit }: NearPoiDirections) => {
  return unit === TimeUnit.WalkingMinutes ? "directions_walk" : "directions_car";
};

/**
 * Computes intermediary model which contains the amount to display as well as the icon and localization token.
 * `nearPoi.walkTime` is in minutes, but we need to display either walking minutes, driving minutes or driving hours,
 * depending on how large that number is.
 */
const getNearPoiModel = (nearPoi: NearPoi): NearPoiModel | null => {
  if (!nearPoi || nearPoi.walkTime < 1) {
    return null;
  }

  const directions = getDirections(nearPoi.walkTime);

  return {
    locToken: getLocToken(directions),
    locData: getLocData(directions, nearPoi.name),
    icon: getIcon(directions),
  };
};

/**
 * Checks hotel model for nearPoi and returns an element with the appropriate text.
 */
export const NearPoiTime = React.memo(({ hotel }: { hotel: Hotel }) => {
  const model: NearPoiModel | null = getNearPoiModel(hotel.nearPoi);

  if (!model) {
    return null;
  }

  return (
    <UitkList
      size={2}
      bullet="icon-standard"
      data={[
        {
          content: (
            <UitkText size={200} overflow="truncate">
              <LocalizedText message={model.locToken} data={model.locData} />
            </UitkText>
          ),
          icon: <UitkIcon size={UitkIconSize.SMALL} name={model.icon} />,
        },
      ]}
    />
  );
});
