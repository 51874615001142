// External libraries
import * as React from "react";

// TYpes
import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";
import { HotelsTemplateComponent } from "components/flexComponents/Hotels/typings";
import { HotelsFlexModuleResult } from "src/typings/microserviceModels/hotels-flex-module";

export const HotelsContext = React.createContext<{
  templateComponent?: HotelsTemplateComponent;
  model?: HotelsFlexModuleResult;
  context?: ExtendedContextStore;
}>({});

export function useHotelsContext() {
  const { templateComponent, context, model } = React.useContext(HotelsContext);

  if (!templateComponent) {
    throw new Error("TemplateComponent not provided");
  }

  if (!model) {
    throw new Error("model not provided");
  }

  if (!context) {
    throw new Error("context not provided");
  }

  return { templateComponent, context, model };
}
