export interface ImageProperties {
  impolicy: string;
  rw: string;
  ra: string;
}

const imageProperties: ImageProperties = {
  impolicy: "resizecrop",
  rw: "402",
  ra: "fit",
};

export default imageProperties;
