import { EgClickstreamData } from "@shared-ui/clickstream-analytics-context";

export const affinityProductsModulePresentedClickstreamEvent = (
  destinationId: string,
  destinationName: string,
  moduleHotelCount: number,
  affinity: string
): EgClickstreamData => {
  return {
    event: {
      event_type: "Impression",
      event_category: "Landing",
      event_name: "affinity_products_module.presented",
      event_version: "1.0.0",
    },
    destination: {
      destination_geo_id: destinationId,
      destination_full_name: destinationName,
    },
    search_request: {
      search_meta: {
        total_displayed_result_count: moduleHotelCount,
      },
    },
    product_list: [
      {
        amenities: [
          {
            amenity_name: affinity,
          },
        ],
      },
    ],
  };
};

export const affinityProductCardSelectedClickstreamEvent = (
  destinationId: string,
  destinationName: string,
  affinity: string,
  hotelId: string,
  hotelPosition: number
): EgClickstreamData => {
  return {
    event: {
      event_type: "Interaction",
      event_category: "Landing",
      event_name: "affinity_product_card.selected",
      event_version: "1.0.0",
    },
    destination: {
      destination_geo_id: destinationId,
      destination_full_name: destinationName,
    },
    product_list: [
      {
        product_id: hotelId,
        search_product_position: hotelPosition,
        amenities: [
          {
            amenity_name: affinity,
          },
        ],
      },
    ],
  };
};
