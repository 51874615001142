import * as React from "react";
import { LocalizedText } from "bernie-l10n";
import { getRatingText } from "src/components/utility/Reviews";
import { useLocalization } from "@shared-ui/localization-context";
import { UitkSpacing } from "uitk-react-spacing";
import { UitkText } from "uitk-react-text";

const OverallReviewRating = ({
  hotelOverallReviewRating,
  language,
  scoreDenominator,
}: {
  hotelOverallReviewRating: number;
  language?: string[];
  scoreDenominator?: number;
}) => {
  const localeWithFallbacks = language?.length ? [language.join("-"), ...language] : [];

  return (
    <UitkText inline weight="bold" size={300} className="hotelRating">
      {[hotelOverallReviewRating.toLocaleString(localeWithFallbacks, { maximumFractionDigits: 1 })]}/{scoreDenominator}
    </UitkText>
  );
};

const RatingTextAndReviewCount = ({
  hotelOverallReviewRating,
  language,
  reviewCount,
}: {
  hotelOverallReviewRating: number;
  reviewCount: number;
  language?: string[];
}) => {
  const l10nMessage = "hotels.review.reviewCount";
  const { formatText } = useLocalization();
  const ratingText = getRatingText(hotelOverallReviewRating);
  const localeWithFallbacks = language?.length ? [language.join("-"), ...language] : [];
  const reviewsCount = formatText(
    l10nMessage,
    reviewCount,
    reviewCount.toLocaleString(localeWithFallbacks, { maximumFractionDigits: 1 })
  );
  const result = ` ${ratingText} (${reviewsCount})`;

  return (
    <UitkText inline size={300}>
      {result}
    </UitkText>
  );
};

interface ReviewCountAndRatingProps {
  hotelOverallReviewRating: number;
  reviewCount: number;
  language?: string[];
  marginOnTop?: boolean;
  scoreDenominator?: number;
  brand?: string;
}

export const ReviewCountAndRating = ({
  hotelOverallReviewRating,
  reviewCount,
  language,
  marginOnTop,
  scoreDenominator = 10,
  brand,
}: ReviewCountAndRatingProps) => {
  const isBrandHotels = brand === "hotels";

  if (!hotelOverallReviewRating || !reviewCount) {
    return (
      <UitkSpacing margin={marginOnTop ? { medium: { blockstart: "one" } } : undefined}>
        <UitkText size={300} overflow={!marginOnTop ? "truncate" : undefined}>
          <LocalizedText message="hotels.review.reviewCount" data={[0, "0"]} />
        </UitkText>
      </UitkSpacing>
    );
  }

  const ReviewCountAndRatingComponent = (
    <UitkSpacing margin={marginOnTop ? "unset" : { medium: { blockstart: "one" } }}>
      <UitkText overflow={!marginOnTop ? "truncate" : undefined}>
        <OverallReviewRating
          hotelOverallReviewRating={hotelOverallReviewRating}
          language={language}
          scoreDenominator={scoreDenominator}
        />
        <RatingTextAndReviewCount
          hotelOverallReviewRating={hotelOverallReviewRating}
          language={language}
          reviewCount={reviewCount}
        />
      </UitkText>
    </UitkSpacing>
  );

  if (!isBrandHotels) {
    return ReviewCountAndRatingComponent;
  }

  return ReviewCountAndRatingComponent;
};
