import * as React from "react";
import { Viewport, ViewLarge, ViewMedium, ViewSmall } from "@shared-ui/viewport-context";

import GridLayout from "./Layouts/GridLayout/GridLayout";
import ListLayout from "./Layouts/ListLayout/ListLayout";
import { CARD_LAYOUT_TYPE } from "../../HotelUtils";
import { ImageLeftLargeProps } from "src/components/flexComponents/Hotels/typings";

const ImageLeftLarge = (props: ImageLeftLargeProps) => {
  const { context, model } = props;
  const { cardLayout } = model;

  return (
    <Viewport>
      <ViewLarge>
        {cardLayout === CARD_LAYOUT_TYPE.LIST ? (
          <ListLayout context={context} model={model} />
        ) : (
          <GridLayout context={context} model={model} columns={3} />
        )}
      </ViewLarge>

      <ViewMedium>
        <ListLayout context={context} model={model} />
      </ViewMedium>

      <ViewSmall>
        <GridLayout context={context} model={model} columns={1} />
      </ViewSmall>
    </Viewport>
  );
};

export default ImageLeftLarge;
