import { Hotel } from "src/typings/microserviceModels/hotels-flex-module";

/**
 * Return the address of the hotel. First,
 * get the address from hotelFormattedFullAddress which should have the complete information of the address.
 * If it is not present, the address is assigned to hotelStreetAddress and hotelCity.
 * If neither is present, is assigned to hotelCity.
 * @param hotel
 */
export const getAddress = ({ hotelFormattedFullAddress, hotelStreetAddress, hotelCity }: Hotel): string => {
  if (hotelFormattedFullAddress) {
    return hotelFormattedFullAddress;
  }
  if (hotelStreetAddress) {
    return `${hotelStreetAddress}, ${hotelCity}`;
  }

  return hotelCity;
};
