// External Libraries
import * as React from "react";

// UITK
import { UitkLayoutFlex, UitkLayoutFlexItem } from "uitk-react-layout-flex";
import { UitkSecondaryButton } from "uitk-react-button";
import { UitkSpacing } from "uitk-react-spacing";
// Utils
import { FlexClickTracker } from "src/components/utility/analytics/FlexClickTracker/FlexClickTracker";

// Types
import { HotelAffinityFilterProps } from "src/components/flexComponents/Hotels/typings";

// Components
import { useHotelsContext } from "components/flexComponents/Hotels/components/HotelsContext";

const ButtonTracked = FlexClickTracker(UitkSecondaryButton);

/**
 * HotelAffinityFilters: Component to render a list of filters
 * @param {HotelAffinityFilterProps} props
 */
export const HotelAffinityFilters: React.FC<HotelAffinityFilterProps> = ({ filters, className }) => {
  const { templateComponent, model } = useHotelsContext();
  const moduleName = templateComponent.type as string;

  return (
    <UitkSpacing margin={{ blockstart: "two" }}>
      <UitkLayoutFlex space="three" wrap="wrap" justifyContent="center" className={className}>
        {filters.map((pill) => (
          <UitkLayoutFlexItem key={pill.key}>
            <ButtonTracked
              moduleName={moduleName}
              href={model.seeAllLink + pill.queryFilter}
              rfrr={`HotelAffinityFilter.actionLink.${pill.label}`}
              data-testid="affinity-filters"
            >
              {pill.label}
            </ButtonTracked>
          </UitkLayoutFlexItem>
        ))}
      </UitkLayoutFlex>
    </UitkSpacing>
  );
};

HotelAffinityFilters.displayName = "HotelAffinityFilters";
