/**
 *
 * @param distance - distance to transform
 * @param locale - specify the language
 * @returns - localized distance
 *
 * @example
 * ```ts
 * getFormattedDistance(12.345, "en_US")
 * ```
 */
export const getFormattedDistance = (distance: number, locale?: string): string => {
  const language = locale?.split(/[-_]/) || [];
  const localeWithFallbacks = language?.length ? [language.join("-"), ...language] : [];
  return distance.toLocaleString(localeWithFallbacks);
};

/**
 *
 * @param distance - distance to round
 * @param decimals - count of digits after the comma
 * @returns - rounded value
 */
export const roundDistance = (distance: number, decimals: number) => {
  const roundTo = 10 ** decimals;
  return Math.round(distance * roundTo) / roundTo;
};
