import * as React from "react";
import { Hotel } from "typings/microserviceModels/hotels-flex-module";
import { useHotelsContext } from "components/flexComponents/Hotels/components/HotelsContext";
import { useEgClickstream } from "@shared-ui/clickstream-analytics-context";
import { egRecsClickstreamSelectedEvent } from "components/flexComponents/Hotels/utils/egRecsEvents";
import { affinityProductCardSelectedClickstreamEvent } from "components/flexComponents/Hotels/utils/affinityClickstreamEvents";
import { AFFINITY_DESTINATION_LANDING_PAGE_TYPES, EG_RECS_STRATEGY } from "../utils/constants";

export const useSendClickstreamSelectedEvent = (hotel: Hotel, index: number) => {
  const { model, context } = useHotelsContext();
  const { strategy } = model;
  const { searchContext } = context;
  const { affinity, pageType, location } = searchContext;
  const isAffinityDestinationLandingPage = pageType
    ? AFFINITY_DESTINATION_LANDING_PAGE_TYPES.includes(pageType)
    : false;
  const track = useEgClickstream();

  return React.useCallback(() => {
    const event = egRecsClickstreamSelectedEvent(hotel, model, `${index}`);
    const affinityProductCardSelectedEvent = affinityProductCardSelectedClickstreamEvent(
      location?.id.toString(),
      location?.name,
      affinity?.code,
      hotel?.hotelId,
      index
    );

    if (EG_RECS_STRATEGY.includes(strategy)) {
      track(event);
    }

    if (affinity && isAffinityDestinationLandingPage) {
      track(affinityProductCardSelectedEvent);
    }
  }, [hotel, model, index, track, strategy]);
};
