import * as React from "react";

import { UitkCard, UitkCardContentSection, UitkCardLink } from "uitk-react-cards";
import { UitkFigure, UitkFigureAspectRatioType, UitkImage } from "uitk-react-images";
import { UitkHeading, UitkText } from "uitk-react-text";
import { UitkRating } from "uitk-react-rating";
import { UitkLayoutFlex } from "uitk-react-layout-flex";
import { UitkLayoutGrid, UitkLayoutGridItem } from "uitk-react-layout-grid";
import { UitkSpacing } from "uitk-react-spacing";

import PriceLockup from "components/flexComponents/Hotels/views/HotelsViews/components/shared/PriceLockup";
import ValueProposition from "components/flexComponents/Hotels/views/HotelsViews/components/shared/ValueProposition";
import DistanceOrRating from "components/flexComponents/Hotels/views/HotelsViews/components/shared/DistanceOrRating";
import DescriptionOrReview from "components/flexComponents/Hotels/views/HotelsViews/components/shared/DescriptionOrReview";
import AmenitiesOrRecommendation from "components/flexComponents/Hotels/views/HotelsViews/components/shared/AmenitiesOrRecommendation";
import { getHotelLink, getHotelLinkRel } from "components/flexComponents/Hotels/components/HotelCard";

import { ratingIcon } from "components/utility/RatingUtil";
import { TrackedLink } from "components/utility/analytics/TrackedLink";

import { getOptimizedImageUrl } from "src/components/flexComponents/Hotels/utils/hotelOptimizedImage";
import { HotelPriceLockup } from "src/components/flexComponents/Hotels/utils/A11y/HotelPriceLockup";
import { useLocalization } from "@shared-ui/localization-context";
import { fireAffinityProductCardSelectedClickstreamEvent } from "src/components/flexComponents/Hotels/utils/fireAffinityProductCardSelectedClickstreamEvent";
import { HotelImageCardProps } from "src/components/flexComponents/Hotels/typings";

const HotelCard = (props: HotelImageCardProps) => {
  const { hotel, model, context, index } = props;
  const { callToAction, destinationId } = model;
  const { hotelName, hotelStarRating, hotelFormattedFullAddress } = hotel;

  const hotelImageUrl = getOptimizedImageUrl(hotel, context);

  const { formatText } = useLocalization();

  return (
    <UitkCard onClick={fireAffinityProductCardSelectedClickstreamEvent(hotel, index)}>
      <UitkCardContentSection>
        <UitkLayoutGrid columns={3} space="three">
          <UitkLayoutGridItem colSpan={1}>
            <UitkFigure ratio={UitkFigureAspectRatioType.R1_1} className="uitk-card-roundcorner-all">
              <UitkImage src={hotelImageUrl} alt={hotel.hotelName} placeholderImage lazyLoading="lazy" />
            </UitkFigure>
          </UitkLayoutGridItem>

          <UitkLayoutGridItem colSpan={2}>
            <UitkLayoutFlex direction="column">
              <UitkHeading tag="h3" size={5} overflow="truncate-2-lines">
                {hotelName}
              </UitkHeading>
              <UitkRating
                rating={hotelStarRating}
                icon={ratingIcon(context.locale, context.site.id)}
                className={context.site.brand}
              />
              <UitkText size={300}>{hotelFormattedFullAddress}</UitkText>
            </UitkLayoutFlex>
          </UitkLayoutGridItem>
        </UitkLayoutGrid>
      </UitkCardContentSection>

      <UitkCardContentSection border="top">
        <UitkLayoutFlex justifyContent="space-between">
          <UitkLayoutFlex direction="column">
            <UitkSpacing margin={{ blockend: "four" }}>
              <ValueProposition hotel={hotel} />
            </UitkSpacing>
            <DistanceOrRating model={model} hotel={hotel} context={context} />
          </UitkLayoutFlex>
          <UitkLayoutFlex>
            <PriceLockup
              hotel={hotel}
              hotelLink={getHotelLink(hotel, model.destinationId, context)}
              priceA11y={new HotelPriceLockup(hotel, formatText)}
              hasGetRatesText
              textAlignment="right"
            />
          </UitkLayoutFlex>
        </UitkLayoutFlex>
      </UitkCardContentSection>

      <UitkCardContentSection border="top">
        <DescriptionOrReview model={model} hotel={hotel} />
        <UitkSpacing margin={{ blockstart: "three" }}>
          <AmenitiesOrRecommendation model={model} hotel={hotel} />
        </UitkSpacing>
      </UitkCardContentSection>

      <UitkCardLink>
        <TrackedLink
          data-testid={`hotelcard-link-${hotel.hotelName}`}
          aria-label={hotelName}
          moduleName="hotels"
          rfrr="card"
          rel={getHotelLinkRel(callToAction)}
          href={getHotelLink(hotel, destinationId || context.searchContext.location?.id, context, callToAction)}
        />
      </UitkCardLink>
    </UitkCard>
  );
};

export default HotelCard;
