import * as React from "react";
import { ValueProposition } from "components/flexComponents/Hotels/components/ValueProposition";
import { Hotel } from "typings/microserviceModels/hotels-flex-module";
import { NearPoiTime } from "components/flexComponents/Hotels/components/NearPoiTime";
import { UitkText } from "uitk-react-text";

export const HotelAddressDistanceToPoiAndValueProposition = ({
  hotel,
  address,
  distance,
  showDistance,
  isViewCarousel,
}: {
  hotel: Hotel;
  address?: string;
  distance?: string;
  showDistance?: boolean;
  isViewCarousel?: boolean;
}) => {
  return (
    <>
      <UitkText size={300} overflow={isViewCarousel ? "truncate" : undefined}>
        {showDistance ? distance : address}
      </UitkText>
      <ValueProposition hotel={hotel} />
      <NearPoiTime hotel={hotel} />
    </>
  );
};
