import { PriceA11y } from "./priceA11yInterface";
import { Hotel } from "src/typings/microserviceModels/hotels-flex-module";

export class HotelPriceLockup implements PriceA11y {
  formattedPrice: string;

  formatText: (key: string, ...args: any[]) => string;

  public constructor(hotel: Hotel, formatText: (key: string, ...args: any[]) => string) {
    this.formattedPrice = hotel.formattedPrice;
    this.formatText = formatText;
  }

  public getA11yText = () => this.formatText("hotels.price.a11y", this.formattedPrice);
}
