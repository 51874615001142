import { HotelsStore } from "stores/HotelsStore";
import { Hotel } from "typings/microserviceModels/hotels-flex-module";
import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";

export function useMapPropertySearchById(
  model: { hotels: Hotel[]; destinationId?: string; showMarkersOnMap?: boolean },
  hotelsStore: HotelsStore,
  contextStore: ExtendedContextStore
) {
  const locationId = model.destinationId ?? contextStore?.searchContext?.location?.id;
  const showHotelsOnMap = typeof model.showMarkersOnMap === "undefined" || model.showMarkersOnMap === true;
  const showHotels = showHotelsOnMap && model.hotels.length > 0;
  if (showHotels) {
    hotelsStore.setAllHotelsOnMapOnFlexHotels(model.hotels, contextStore, locationId);
  }
}

export default useMapPropertySearchById;
