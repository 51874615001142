// External Libraries
import * as React from "react";
import { formatText, formatDateString } from "bernie-l10n";

// Types
import { Hotel } from "typings/microserviceModels/hotels-flex-module";

// UITK
import { UitkLayoutFlex, UitkLayoutFlexItem } from "uitk-react-layout-flex";
import { UitkSpacing } from "uitk-react-spacing";
import { UitkText } from "uitk-react-text";

// Components
import { ReviewCountAndRating } from "components/flexComponents/Hotels/components/ReviewCountAndRating";
import { showReviewCountAndRating } from "components/flexComponents/Hotels/components/FeaturedReviewContent";
import { useHotelsContext } from "components/flexComponents/Hotels/components/HotelsContext";
import { HotelName, HotelRating } from "components/flexComponents/Hotels/components/HotelNameRatingAndAddress";
import DistanceOrRating from "components/flexComponents/Hotels/views/HotelsViews/components/shared/DistanceOrRating";

// Utils
import { getAddress } from "components/flexComponents/Hotels/utils/getAddress";
import { ratingIcon } from "components/utility/RatingUtil";
import PriceLockup from "../../views/HotelsViews/components/shared/PriceLockup";
import { HotelPriceLockupWithDates } from "../../utils/A11y/HotelPriceLockupWithDates";
import { getHotelLink } from "../HotelCard";
import { isVariantEnabled } from "src/components/utility/experiment";
import { ViewLarge, Viewport, ViewSmall } from "@shared-ui/viewport-context";

export interface SummarizedContentViewProps {
  showPrice?: boolean;
  showAddress?: boolean;
  showNeighborhood?: boolean;
  showDistance?: boolean;
  showRating?: boolean;
}

interface SummarizedContentProps extends SummarizedContentViewProps {
  hotel: Hotel;
}

export const SummarizedContent: React.FC<SummarizedContentProps> = ({
  hotel,
  showPrice = true,
  showAddress = true,
  showNeighborhood = false,
  showDistance = false,
  showRating = false,
}) => {
  const {
    hotelName,
    reviewCount,
    hotelOverallReviewRating,
    featuredReviews,
    hotelStarRating,
    neighborhoodName,
    scoreDenominator,
  } = hotel;
  const { context, model } = useHotelsContext();

  const language = context.locale?.split(/[_-]/);
  const hotelRatingIcon = ratingIcon(context.locale, context.site?.id);
  const brand = context.site.brand;

  const isVariant = isVariantEnabled(context, "Progressive_disclosure_of_content_DLPs");

  const reviewCountAndRating = showReviewCountAndRating(
    model.showGuestRating,
    reviewCount,
    hotelOverallReviewRating,
    featuredReviews
  ) && (
    <ReviewCountAndRating
      hotelOverallReviewRating={hotelOverallReviewRating}
      reviewCount={reviewCount}
      language={language}
      scoreDenominator={scoreDenominator}
      brand={brand}
    />
  );

  const priceSection = showPrice && (
    <UitkLayoutFlexItem shrink={0}>
      <div>
        <PriceLockup
          hotel={hotel}
          hotelLink={getHotelLink(hotel, model.destinationId, context)}
          priceA11y={new HotelPriceLockupWithDates(hotel, formatText, formatDateString)}
          hasGetRatesText
        />
      </div>
    </UitkLayoutFlexItem>
  );

  const titleAndDescription = (
    <UitkSpacing margin={{ inlineend: "one" }}>
      <div>
        <UitkSpacing margin={{ blockend: "one" }}>
          <div>
            <HotelName tag="h3" overflow="truncate-2-lines" hotelName={hotelName} />
            {showRating && (
              <UitkSpacing margin={{ blockend: "one" }}>
                <HotelRating hotelStarRating={hotelStarRating} hotelRatingIcon={hotelRatingIcon} />
              </UitkSpacing>
            )}
          </div>
        </UitkSpacing>
        {showAddress && (
          <UitkSpacing padding={{ blockend: "one" }}>
            <UitkText size={300}>{getAddress(hotel)}</UitkText>
          </UitkSpacing>
        )}
        {showNeighborhood && neighborhoodName && (
          <UitkLayoutFlex justifyContent="start">
            <UitkLayoutFlexItem>
              <UitkSpacing padding={{ blockend: "one" }} margin={{ inlineend: "one" }}>
                <UitkText>{neighborhoodName}</UitkText>
              </UitkSpacing>
            </UitkLayoutFlexItem>
            {showDistance && (
              <>
                <UitkLayoutFlexItem>
                  <span>&#8208;</span>
                </UitkLayoutFlexItem>
                <UitkLayoutFlexItem>
                  <DistanceOrRating model={model} hotel={hotel} context={context} showIcon={false} />
                </UitkLayoutFlexItem>
              </>
            )}
          </UitkLayoutFlex>
        )}
      </div>
    </UitkSpacing>
  );

  return (
    <>
      {isVariant ? (
        <>
          <Viewport>
            <ViewSmall>
              <UitkLayoutFlex direction="column" justifyContent="space-between" blockSize="full_size">
                <UitkLayoutFlex justifyContent="space-between" wrap="wrap">
                  <UitkLayoutFlexItem shrink={1}>{titleAndDescription}</UitkLayoutFlexItem>
                </UitkLayoutFlex>
                {reviewCountAndRating}
                {priceSection}
              </UitkLayoutFlex>
            </ViewSmall>
            <ViewLarge>
              <UitkLayoutFlex direction="column" justifyContent="space-between">
                <UitkLayoutFlex justifyContent="space-between">
                  <UitkLayoutFlexItem shrink={1}>{titleAndDescription}</UitkLayoutFlexItem>
                  {priceSection}
                </UitkLayoutFlex>
                {reviewCountAndRating}
              </UitkLayoutFlex>
            </ViewLarge>
          </Viewport>
        </>
      ) : (
        <>
          <UitkLayoutFlex direction="column" justifyContent="space-between">
            <UitkLayoutFlex justifyContent="space-between">
              <UitkLayoutFlexItem shrink={1}>{titleAndDescription}</UitkLayoutFlexItem>
              {priceSection}
            </UitkLayoutFlex>
            {reviewCountAndRating}
          </UitkLayoutFlex>
        </>
      )}
    </>
  );
};
