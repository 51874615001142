import { TravelerReviewContent } from "typings/microserviceModels/hotels-flex-module";
import * as React from "react";
import { GreatForAffinityBadge } from "./GreatForAffinityBadge";

export interface HotelBadgeProps {
  travelerReviewContent?: TravelerReviewContent;
}

export const HotelBadge = (props: HotelBadgeProps) => {
  const { travelerReviewContent } = props;

  if (travelerReviewContent) {
    return <GreatForAffinityBadge travelerReviewContent={travelerReviewContent} />;
  }

  return null;
};
