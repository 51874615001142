import * as React from "react";
import { Viewport, ViewLarge, ViewMedium, ViewSmall } from "@shared-ui/viewport-context";

import GridLayout from "./Layouts/GridLayout/GridLayout";
import ListLayout from "./Layouts/ListLayout/ListLayout";
import ExpandoLayout from "./Layouts/ExpandoLayout/ExpandoLayout";
import { CARD_LAYOUT_TYPE } from "../../HotelUtils";
import { ImageLeftSmallProps } from "src/components/flexComponents/Hotels/typings";

const ImageLeftSmall = (props: ImageLeftSmallProps) => {
  const { context, model } = props;
  const { cardLayout } = model;

  return (
    <Viewport>
      <ViewLarge>
        {cardLayout === CARD_LAYOUT_TYPE.LIST ? (
          <ListLayout context={context} model={model} />
        ) : (
          <GridLayout context={context} model={model} />
        )}
      </ViewLarge>

      <ViewMedium>
        <ListLayout context={context} model={model} />
      </ViewMedium>

      <ViewSmall>
        <ExpandoLayout context={context} model={model} />
      </ViewSmall>
    </Viewport>
  );
};

export default ImageLeftSmall;
