import * as React from "react";
import { ValueProposition } from "components/flexComponents/Hotels/components/ValueProposition";
import { Hotel } from "typings/microserviceModels/hotels-flex-module";
import { UitkIcon, UitkIconSize } from "uitk-react-icons";
import { UitkSpacing } from "uitk-react-spacing";
import { UitkText } from "uitk-react-text";

export const HotelAddressDistanceToPoiAndValuePropositionCOMET = ({
  hotel,
  address,
  distance,
  showAddress,
  showDistance,
  isViewCarousel,
}: {
  hotel: Hotel;
  address?: string;
  distance?: string;
  showAddress?: boolean;
  showDistance?: boolean;
  isViewCarousel?: boolean;
}) => {
  return (
    <>
      {showAddress && <UitkText size={300}>{address}</UitkText>}

      <ValueProposition hotel={hotel} />

      {showDistance && (
        <UitkSpacing padding={isViewCarousel ? { blockstart: "unset" } : { blockstart: "three" }}>
          <div>
            <UitkIcon name="place" size={UitkIconSize.MEDIUM} />
            <UitkSpacing padding={{ inlinestart: "two" }}>
              <UitkText inline size={300} overflow={isViewCarousel ? "truncate" : undefined}>
                {distance}
              </UitkText>
            </UitkSpacing>
          </div>
        </UitkSpacing>
      )}
    </>
  );
};
