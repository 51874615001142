import { Hotel } from "typings/microserviceModels/hotels-flex-module";

/**
 * Return the Schema Structured Data for hotels and AA.
 * Hotel (and all subclasses) are valid only when an image and name value is present
 * @param hotels
 */
export const hotelStructuredData = (hotels: Hotel[], topCityIndex?: number, domain?: string): string => {
  if (topCityIndex === 1) return listItemHotelStructuredData(hotels, domain);

  const hotelStructuredDatas = hotels
    .filter((hotel) => hotel.hotelName && hotel.hotelImageUrl)
    .map((hotel) => {
      return {
        "@context": "http://schema.org",
        "@type": getStructuredDataSchemaType(hotel),
        name: hotel.hotelName,
        description: hotel.shortDescription,
        address: getHotelAddress(hotel),
        image: hotel.hotelImageUrl,
        starRating: {
          "@type": "Rating",
          ratingValue: hotel.hotelStarRating,
        },
        priceRange: hotel.formattedPrice,
        aggregateRating: {
          "@type": "AggregateRating",
          bestRating: hotel.scoreDenominator,
          ratingValue: hotel.hotelOverallReviewRating,
          reviewCount: hotel.reviewCount,
        },
      };
    });

  return JSON.stringify(hotelStructuredDatas);
};

export const listItemHotelStructuredData = (hotels: Hotel[], domain?: string): string => {
  const hotelStructuredDatas = {
    "@context": "http://schema.org",
    "@type": "ItemList",
    itemListElement: hotels
      .filter((hotel) => hotel.hotelName && hotel.hotelImageUrl && domain)
      .map((hotel, index) => {
        return {
          "@type": "ListItem",
          position: index + 1,
          item: {
            "@type": getStructuredDataSchemaType(hotel),
            name: hotel.hotelName,
            description: hotel.shortDescription,
            address: getHotelAddress(hotel),
            image: `https:${hotel.hotelImageUrl}`,
            starRating: {
              "@type": "Rating",
              ratingValue: hotel.hotelStarRating,
            },
            priceRange: hotel.formattedPrice,
            aggregateRating: {
              "@type": "AggregateRating",
              bestRating: hotel.scoreDenominator,
              ratingValue: hotel.hotelOverallReviewRating,
              reviewCount: hotel.reviewCount,
            },
            url: `https://${domain}${hotel.datelessHotelUrl}`,
          },
        };
      }),
  };

  return JSON.stringify(hotelStructuredDatas);
};

function getStructuredDataSchemaType(hotel: Hotel) {
  switch (hotel.structure) {
    case "BED_AND_BREAKFAST":
      return "BedAndBreakfast";
    case "HOSTEL":
      return "Hostel";
    case "MOTEL":
      return "Motel";
    case "HOTEL_RESORT":
      return "Resort";
    case "CARAVAN_PARK":
      return "Campground";
    default:
      return "Hotel";
  }
}

function getHotelAddress(hotel: Hotel) {
  return hotel.hotelFormattedFullAddress
    ? hotel.hotelFormattedFullAddress
    : {
        "@type": "PostalAddress",
        addressCountry: hotel.hotelCountryCode,
        addressRegion: hotel.hotelProvince,
        addressLocality: hotel.hotelCity,
        postalCode: hotel.hotelPostalCode,
        streetAddress: hotel.hotelStreetAddress,
      };
}
