import * as React from "react";
import { Viewport, ViewLarge, ViewMedium, ViewSmall } from "@shared-ui/viewport-context";

import GridLayout from "./Layouts/GridLayout/GridLayout";
import ListLayout from "./Layouts/ListLayout/ListLayout";
import ExpandoLayout from "./Layouts/ExpandoLayout/ExpandoLayout";
import { ImageTopProps } from "src/components/flexComponents/Hotels/typings";

const ImageTop = (props: ImageTopProps) => {
  const { context, model } = props;

  return (
    <Viewport>
      <ViewLarge>
        <GridLayout context={context} model={model} />
      </ViewLarge>

      <ViewMedium>
        <ListLayout context={context} model={model} />
      </ViewMedium>

      <ViewSmall>
        <ExpandoLayout context={context} model={model} />
      </ViewSmall>
    </Viewport>
  );
};

export default ImageTop;
