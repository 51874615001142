import { Hotel, HotelsFlexModuleResult } from "typings/microserviceModels/hotels-flex-module";
import { EgClickstreamData } from "@shared-ui/clickstream-analytics-context";

export const egRecsClickstreamPresentedEvent = (
  model: HotelsFlexModuleResult,
  hotel?: Hotel[],
  position?: number,
  isNewPresentedBehaviour?: boolean
): EgClickstreamData => {
  const hotels = isNewPresentedBehaviour ? hotel : model.hotels;
  return {
    experience: {
      page_name: "Hotels",
    },
    event: {
      event_version: "1.1.0",
      event_name: "recommendations_module.presented",
      event_type: "Impression",
      event_category: "recommendation_module",
    },
    recommendation_module: {
      eg_recommendation_response_id: model.recommendationId,
      recommendations: hotels?.map((hotel, index) => {
        const cardPosition = isNewPresentedBehaviour ? position : index;
        return {
          recommendation_id: hotel.hotelId,
          recommendation_position: cardPosition?.toString(),
          presentation_details_price_displayed: hotel.hotelPrice?.value || "null",
          presentation_details_currency_code: hotel.hotelPrice?.currency || "null",
          presentation_details_recommendation_name: hotel.hotelName,
        };
      }),
    },
  };
};

export const egRecsClickstreamSelectedEvent = (
  hotel: Hotel,
  model: HotelsFlexModuleResult,
  position: string
): EgClickstreamData => ({
  experience: {
    page_name: "Hotels",
  },
  event: {
    event_version: "1.1.0",
    event_name: "recommendations_module.selected",
    event_type: "Interaction",
    event_category: "recommendation_module",
  },
  recommendation_module: {
    eg_recommendation_response_id: model.recommendationId || "null",
    recommendations: [
      {
        recommendation_id: hotel.hotelId,
        recommendation_position: position,
        presentation_details_price_displayed: hotel.hotelPrice?.value || "null",
        presentation_details_currency_code: hotel.hotelPrice?.currency || "null",
        presentation_details_recommendation_name: hotel.hotelName,
      },
    ],
  },
});
