import * as React from "react";

import { UitkLayoutGrid } from "uitk-react-layout-grid";
import { UitkSpacing } from "uitk-react-spacing";

import HotelCard from "./HotelCard";
import { ItemKeyHelper } from "components/utility/ItemKeyHelper";

import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";
import { HotelsFlexModuleResult } from "typings/microserviceModels/hotels-flex-module";

const GridLayout = (props: { context: ExtendedContextStore; model: HotelsFlexModuleResult }) => {
  const { context, model } = props;
  const { hotels } = model;

  const keyHelper = new ItemKeyHelper("hotels");

  return (
    <UitkSpacing margin={{ blockstart: "three" }}>
      <UitkLayoutGrid columns={3} space="three">
        {hotels.map((hotel, index) => (
          <HotelCard key={keyHelper.next()} context={context} model={model} hotel={hotel} index={index} />
        ))}
      </UitkLayoutGrid>
    </UitkSpacing>
  );
};

export default GridLayout;
