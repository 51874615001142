import * as React from "react";
import { LocalizedText } from "bernie-l10n";
import { classNames } from "uitk-react-utils";
import { locKeys } from "src/components/flexComponents/WizardHotelPWA/components/l10n";
import { useHotelsContext } from "components/flexComponents/Hotels/components/HotelsContext";
import { UitkText } from "uitk-react-text";

export const LegalText = ({ className }: { className?: string }) => {
  const { model } = useHotelsContext();

  if (!model.showLegalText) {
    return null;
  }

  return (
    <UitkText size={200} align="center" className={classNames("legalText", className)}>
      <LocalizedText message={locKeys.hotelLegalDisclaimer} />
    </UitkText>
  );
};
