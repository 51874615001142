import { PriceA11y } from "./priceA11yInterface";
import { Hotel } from "src/typings/microserviceModels/hotels-flex-module";
import { DATE_FORMAT } from "../constants";
import { ExtendedHotel } from "src/services/adapters/BexApiPropertyAdapter";

export class HotelPriceLockupWithDates implements PriceA11y {
  formattedPrice: string;

  checkInDateFormatted: string;

  checkOutDateFormatted: string;

  formatText: (key: string, ...args: any[]) => string;

  public constructor(
    hotel: Hotel,
    formatText: (key: string, ...args: any[]) => string,
    formatDateString: (key: string, ...args: any[]) => string
  ) {
    this.formattedPrice = hotel.formattedPrice;
    const { checkIn, checkOut } = buildGetHotelDates(formatDateString)(hotel);
    this.checkInDateFormatted = checkIn;
    this.checkOutDateFormatted = checkOut;
    this.formatText = formatText;
  }

  public getA11yText = () =>
    this.formatText(
      "hotels.priceWithDates.a11y",
      this.formattedPrice,
      this.checkInDateFormatted,
      this.checkOutDateFormatted
    );
}

function buildGetHotelDates(formatDateString: (key: string, ...args: any[]) => string) {
  return (hotel: ExtendedHotel) => {
    if (hotel.hotelPrice?.messages?.[1]?.value) {
      const [checkIn, checkOut] = hotel.hotelPrice.messages[1].value.split("-");

      return {
        checkIn: checkIn?.trim() || "",
        checkOut: checkOut?.trim() || "",
      };
    }

    return {
      checkIn: formatDateString(hotel.isoCheckIn, { skeleton: DATE_FORMAT }),
      checkOut: formatDateString(hotel.isoCheckOut, { skeleton: DATE_FORMAT }),
    };
  };
}
